import React from 'react';
import './ModalCar.css';

const ModalCar = ({ car, closeModal }) => {
    return (
        <div className="modal">
            <div className="modal-content">
                <button className="close-btn" onClick={closeModal}>&times;</button> {/* X button */}
                <h2>{car.name}</h2>
                <p>{car.details}</p>
                <div className="modal-images">
                    {car.images.map((img, index) => (
                        <img key={index} src={img} alt={`${car.name} ${index + 1}`} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ModalCar;
