import React, { useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import HeroSection from '../HeroSection';
import './Car.css'; // Stilimi për komponentin Car

import ModalCar from './ModalCar'; // Komponenti i modalit për detajet e makinave

const Car = () => {
    const [selectedCar, setSelectedCar] = useState(null); // Gjendja për modalin

    // Array me të dhëna për makinat
    const cars = [
        { 
            id: 1, 
            name: "Viano 7+1", 
            type: "Engine 3.0", 
            description: " A spacious and versatile luxury vehicle",
            details: "The Viano 7+1 is a spacious and versatile luxury vehicle, designed to accommodate up to eight passengers with maximum comfort. Equipped with a powerful 3.0-liter engine and automatic transmission, it offers a smooth and effortless driving experience. Ideal for group travel or family outings, this Viano model combines performance with elegance, featuring ample legroom, high-quality interior finishes, and modern amenities to ensure a comfortable journey for all passengers. Automatic",
            images: ["/images/Makina/mercedes-viano.png"]
        },
        { 
            id: 2, 
            name: "Golf 5+", 
            type: "Engine 1.9", 
            description: "A reliable and compact car known for its efficiency and practicality.",
            details: "The Golf 5+ is a reliable and compact car known for its efficiency and practicality. Featuring a manual transmission, this model gives drivers greater control and an engaging driving experience. With seating for five, the Golf 5+ offers a comfortable interior with well-designed seating and practical storage options, making it an excellent choice for both city driving and longer trips. Its compact size and easy handling make it ideal for navigating urban environments while providing the quality and durability expected from the Golf series.",
            images: ["/images/Makina/Golf.jpg"]
        },
        { 
            id: 3, 
            name: "Toyota corolla 6+1", 
            type: "Engine 2.2", 
            description: " A versatile and reliable choice for drivers seeking efficiency and control.",
            details: "The Toyota Corolla 6+1 with a 2.2-liter engine and manual transmission is a versatile and reliable choice for drivers seeking efficiency and control. This model seats up to seven, offering extra space for passengers or cargo, ideal for family trips or group outings. Known for its dependable performance, the Corolla's 2.2-liter engine delivers a smooth balance of power and fuel efficiency, making it suitable for both city commuting and highway driving. With Toyota's reputation for durability and quality, the Corolla 6+1 is a practical, comfortable option for various travel needs.",
            images: ["/images/Makina/Toyota.png"]
        },
    ];

    // Funksion për të hapur modalin
    const openModal = (car) => {
        setSelectedCar(car);
    };

    // Funksion për të mbyllur modalin
    const closeModal = () => {
        setSelectedCar(null);
    };

    return (
        <div>
            <HeroSection />
            <Navbar />

            <div className="cars-container">
                <h1 className="section-title">Our Featured Cars</h1>
                <div className="car-grid">
                    {cars.map((car) => (
                        <div key={car.id} className="car-card">
                            <h5 className="car-title">{car.name}</h5>
                            <p className="car-type">{car.type}</p>
                            <p className="car-description">{car.description}</p>
                            <button className="learn-more-btn" onClick={() => openModal(car)}>
                                Learn More
                            </button>
                        </div>
                    ))}
                </div>
            </div>

            {selectedCar && (
                <ModalCar car={selectedCar} closeModal={closeModal} />
            )}

            <Footer />
        </div>
    );
};

export default Car;
