import React from 'react';
import './Modal.css';

const Modal = ({ itinerary, closeModal }) => {
  const whatsappLink = 'https://wa.me/+355684170770'; // Zëvendëso me numrin tënd të WhatsApp

  return (
    <div className="modal-overlay" onClick={closeModal}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="close-btn" onClick={closeModal}>&times;</span>
        <h2>{itinerary.title}</h2>
        <p>{itinerary.details}</p>

        {/* Shfaq imazhet e itinerarit */}
        <div className="modal-images">
          {itinerary.images.map((image, index) => (
            <img key={index} src={image} alt={`${itinerary.title} - ${index + 1}`} />
          ))}
        </div>

        {/* Butoni i kontaktit në WhatsApp */}
        <div className="whatsapp-contact">
          <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
            <img 
              src="/images/WP.png" 
              alt="Contact us on WhatsApp" 
              className="whatsapp-icon" 
            />
            <span>Contact</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Modal;
