import React from 'react';
import { Link } from 'react-router-dom';
import './ClinicPage.css';
import HeroSection from '../HeroSection';
import Footer from '../Footer';

const ClinicPage = () => {
    console.log("ClinicPage rendered");  // Verifikoni në konsolën e shfletuesit

    return (
        <div>
            <HeroSection />

            <div className="clinic-page-container">

                <div className="clinic-content">
                    <h1 className="clinic-title">Aesthetic Clinic</h1>

                    <div className="clinic-image-container">
                        <img
                            src={`${process.env.PUBLIC_URL}/images/Klinika/Keit.png`}
                            alt="Clinic"
                            className="clinic-image"
                        />
                    </div>

                    <div className="clinic-description">
                        <p>Welcome to our clinic. We offer a wide range of medical services...</p>
                    </div>

                    <div className="clinic-link-container">
                        <Link to="https://www.keit.al/" className="clinic-link">
                            Visit Our Clinic
                        </Link>
                    </div>
                </div>

                <Footer />
            </div>
        </div>
    );
};

export default ClinicPage;
