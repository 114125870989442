import React from 'react';
import { Link } from 'react-router-dom';  // Add this import statement
import './License.css';
import HeroSection from '../HeroSection';
import Footer from '../Footer';

const License = () => {
    return (
        <div>
            <HeroSection />
            <div className="license-container">
                <h1 className="license-title">Licensed Guides</h1>
                <div className="license-content">
                    <p>Our licensed guides provide expert knowledge and ensure safety and satisfaction during your journey.</p>
                    <a 
                        href="https://wa.me/+4917620544736?text=Hello!%20I%20would%20like%20to%20book%20a%20guide." 
                        className="license-booking-link" 
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        Book a Guide
                    </a>  
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default License;
