import React, { useState } from 'react'
import emailjs from '@emailjs/browser';
import './EmailForm.css';

const EmailForm = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = 'service_mnb42l3';
    const templateId = 'template_7kmyz2k';
    const publicKey = 'TNKGXR5yvcvwQkEjq';

    const userParams = {
      from_name: name,
      from_email: email,
      to_name: 'Wanderlust Albania',
      message: message,
    };

    emailjs.send(serviceId, templateId, userParams, publicKey)
      .then((response) => {
        console.log('Email sent successfully!', response);
        setName('');
        setEmail('');
        setMessage('');
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  }

  return (
    <>
      <div className='small-bar'></div>
      <section className='email-form'>
        <h2 id="email-section" className='header-text'>Send us an email</h2>
        <div className='form-container'>
          <div className='form-text'>
            <p>Wanderlust Albania is a company that solves every need of every type of tourism in the most environmentally friendly way possible.</p>
            <p>Because our vision is to be 100% Ecco-Friendly and we are trying hard. You only need to express your wish and the 'Genie' will fulfill it. Our concept is to be part of your vacation every step of the journey by offering our services. We can offer you transfers, the accommodation in the Hotel, guided tours, (the rent of a bus, car, motorcycle, Bike) ect... We can suggest you how to get to know Albanian country at every dimension. Wanderlust Albania is proud to have satisfied guests who can suggest other friends to choose us for an unforgettable experience. Wanderlust Albania, the solution."</p>
          </div>
          <form onSubmit={handleSubmit} className='emailForm'>
            <input
              type="text"
              placeholder="Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="email"
              placeholder="Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <textarea
              cols="30"
              rows="10"
              placeholder="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            >
            </textarea>
            <button type="submit">Send Email</button>
          </form>
        </div>
      </section>
    </>
  )
}

export default EmailForm
