import React, { useState } from "react";
import "./TopNavbar.css";

const TopNavbar = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [language, setLanguage] = useState("en");

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleLanguageChange = (e) => {
        setLanguage(e.target.value);
    };

    return (
        <div className="top-navbar">
            <div className="navbar-left">
                {/* Vendosim logon në vend të titullit */}
                <img src="/images/WL.png" alt="Wanderlust Albania Logo" className="navbar-logo" />
            </div>

            <div className="navbar-right">
                {/* Email dhe Location Links */}
                <div className="contact-info">
                    <a href="mailto:info@wanderlustalbania.com" className="email-link">
                        <i className="fas fa-envelope"></i> info@wanderlustalbania.com
                    </a>
                    <a href="https://www.google.com/maps/place/Your+Location" className="location-link">
                        <i className="fas fa-map-marker-alt"></i> Location
                    </a>
                </div>
                <select
                    className="translator"
                    value={language}
                    onChange={handleLanguageChange}
                >
                    <option value="en">English</option>
                    <option value="sq">Shqip</option>
                    {/* Shtoni mundësi për gjuhë të tjera */}
                </select>
            </div>
        </div>
    );
};

export default TopNavbar;
