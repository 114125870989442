import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';  // Vetëm një herë
import './Slide.css'; // Sigurohu që ky skedar CSS ekziston

const Slider = () => {
    const [activeTab, setActiveTab] = useState('Home'); // State për tab-in aktiv

    // Funksioni për të ndryshuar tab-in aktiv
    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    return (
        <div className="wrapper">
            <ul className="tabs-box">
                <li
                    className={`tab ${activeTab === 'Home' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Home')}
                >
                    <NavLink to="/" exact>Home</NavLink>
                </li>
                <li className="tab">
                    <NavLink to="/about" activeClassName="active">
                        About Us
                    </NavLink>
                </li>

                <li
                    className={`tab ${activeTab === 'Hotels' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Hotels')}
                >
                    <NavLink to="/Hotels">Hotels</NavLink>
                </li>
                <li
                    className={`tab ${activeTab === 'Travels' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Travels')}
                >
                    <NavLink to="/Travels">Travels</NavLink>
                </li>
                <li
                    className={`tab ${activeTab === 'Licensed Guides' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Licensed Guides')}
                >
                    <NavLink to="/licensed-guides">Licensed Guides</NavLink>
                </li>
                <li
                    className={`tab ${activeTab === 'Esthetic' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Esthetic')}
                >
                    <NavLink to="/esthetic">Esthetic</NavLink>
                </li>
                <li
                    className={`tab ${activeTab === 'Places to Visit' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Places to Visit')}
                >
                    <NavLink to="/places-to-visit">Places to Visit</NavLink>
                </li>
            </ul>
        </div>
    );
};

export default Slider;
