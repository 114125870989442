import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import $ from 'jquery';

function HeroSection() {
    const scrollToEmailForm = () => {
        $('html, body').animate({
            scrollTop: $('#email-section').offset().top
        }, 1500);
    };

    const scrollToCards = () => {
        $('html, body').animate({
            scrollTop: $('#cards-div').offset().top
        }, 1000);
    };

    return (
        <div id='top' className='hero-container'>
            {/* Video in vend të imazhit */}
            <video className="hero-video" autoPlay loop muted>
                <source src={`${process.env.PUBLIC_URL}/images/VID.mp4`} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <div className='hero-text'>Wanderlust</div> {/* Shtuar teksti */}

            <div className='hero-btns'>
                <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large' onClick={scrollToEmailForm}>
                    EMAIL
                </Button>
                <Button className='btns' buttonStyle='btn--primary' buttonSize='btn--large' onClick={scrollToCards}>
                    View
                </Button>
            </div>
        </div>
    );
}

export default HeroSection;
