import React from 'react';
import './Features.css';

function Features() {
    return (
        <section className="features-container">
            <h2>Features</h2>
            <div className="features-grid">
                <div className="feature-item">
                    <img className='icon' src={`${process.env.PUBLIC_URL}/images/face-48.png`} alt='Face Icon' />
                    <h3>Personalized Trips</h3>
                    <p>Discover personalized trips tailored just for you, where every journey is a unique adventure waiting to unfold!</p>
                </div>
                <div className="feature-item">
                    <img className='icon' src={`${process.env.PUBLIC_URL}/images/movie-48.png`} alt='Movie Icon' />
                    <h3>Create unforgetable memories</h3>
                    <p>Create unforgettable memories with personalized trips designed just for you!</p>
                </div>
                <div className="feature-item">
                    <img className='icon' src={`${process.env.PUBLIC_URL}/images/map_marker-48.png`} alt='Map Icon' />
                    <h3>Location Services</h3>
                    <p>Experience unforgettable adventures with our expert location services!</p>
                </div>
                <div className="feature-item">
                    <img className='icon' src={`${process.env.PUBLIC_URL}/images/genius-48.png`} alt='Genius Icon' />
                    <h3>Smart Recommendations</h3>
                    <p>Get personalized suggestions based on your interests and activity with our AI-driven algorithms.</p>
                </div>
                <div className="feature-item">
                    <img className='icon' src={`${process.env.PUBLIC_URL}/images/alarm_clock-48.png`} alt='Alarm Icon' />
                    <h3>Event Reminders</h3>
                    <p>Never miss an important event or deadline with our easy-to-use alarm and reminder system.</p>
                </div>
                <div className="feature-item">
                    <img className='icon' src={`${process.env.PUBLIC_URL}/images/airplane-48.png`} alt='Airplane Icon' />
                    <h3>Travel Planning</h3>
                    <p>Plan your next adventure with our comprehensive travel tools and resources.</p>
                </div>
            </div>
        </section>
    );
}

export default Features;
