import React, { useState } from 'react';
import Footer from '../Footer';
import HeroSection from '../HeroSection';
import './Travels.css';
import Modal from './Modal'; // Ndryshoni rrugën sipas strukturës së dosjeve tuaja

const Travel = () => {
    const [selectedItinerary, setSelectedItinerary] = useState(null); // Gjendja e modalit

    // Array me itinerare me të dhëna shtesë për modalin
    const itineraries = [
        { 
            id: 1, 
            title: "DURRES AND SKANDERBEG COGNAC FACTORY  ", 
            description: "A FULL DAY TOUR",
            details: "On this half-day excursion, you will explore the ancient city of Durrës, known as Dyrrachium. Visit key highlights, including the stunning Roman amphitheater and the Venetian tower. Enjoy a walk along the Byzantine city walls and tour the largest cognac factory in Albania. Your journey begins at 9:00 am towards Durrës. As you stroll along the boulevard, take in the city’s 1930s architecture. Explore the 2nd-century Roman amphitheater, built during the reign of Emperor Trajan, one of the largest in the Balkans. The tour includes the city forum ruins, Roman baths, the town hall, and the Byzantine walls. Next, visit the 'Gjergj Kastrioti Skenderbeu' cognac factory, where this iconic Albanian drink has been distilled since 1933. Enjoy a guided tour and tasting of the legendary cognac. Return to your hotel after an enriching experience.",
            images: ["/images/4.JPG", "/images/s.jpg"] 
        
        },
        { 
            id: 2, 
            title: "TIRANA AND KRUJA A FULL DAY TOUR", 
            description: "A FULL DAY TOUR",
            details: "Explore the medieval town of Kruja and the highlights of Albania’s vibrant capital, Tirana. Begin the journey at 9:00 am with a city tour of Tirana, featuring Skanderbeg Square, the Ethem Bey Mosque, the Clock Tower, and the colorful Blloku district. Visit the National Historical Museum to learn about Albania’s rich history. Explore the opera, Palace of Culture, and both Orthodox and Catholic cathedrals. Continue to the pyramid, a landmark dedicated to former dictator Enver Hoxha. Afterward, head to Kruja, a medieval town perched over 600m above sea level. Visit the Kruja Castle, dating back to the 5th century, and the Skanderbeg Museum, where you’ll step back into medieval history. Don't miss the Old Bazaar for traditional crafts and souvenirs. Optional traditional lunch included before returning to the hotel.",
            images: ["/images/tirana-main-image-scaled.jpg"]
        },
        { 
            id: 3, 
            title: "SHKODRA DAY TRIPS", 
            description: "A FULL DAY TOUR",
            details: "Discover Shkodra, Albania's cultural capital, renowned for its rich history and stunning natural beauty. Departing at 9:00 am, explore this ancient city, surrounded by the Albanian Alps. Visit the iconic Rozafa Castle, dating back to the 1st millennium BC, with its impressive history through Roman, Venetian, and Turkish rule. After touring the castle, stroll through Shkodra's charming old town, known for its mix of mosques, churches, and classicist houses. Visit the Leaden Mosque and the Historical Museum to learn about the city's diverse heritage. Return to your hotel after a memorable day in Shkodra.",
            images: ["/images/d6.jpg"]
        },
        { 
            id: 4, 
            title: "VLORA, KARABURUN-SAZAN NATIONAL MARINE PARK", 
            description: "A FULL DAY TOUR",
            details: "Explore the stunning Bay of Vlora and the Karaburun-Sazan National Marine Park on a full-day boat tour. Visit the beaches, famous for their crystal-clear waters and small coves, and enjoy breathtaking landscapes with mountains rising up to 1200 m. The tour includes a city visit to Vlora, where Albania's independence was declared in 1912. Depart at 7:30 a.m. for a boat trip to the Karaburun Peninsula, followed by relaxation on the beach. After a packed lunch, continue to Sazan Island, known for its mysterious military past. Visit the Haxhi Ali cave, then enjoy a swim at St. Vasili beach before returning to Vlora for a city tour and hotel drop-off.",
            images: ["/images/146.jpg"]
        },
        { 
            id: 5, 
            title: "UNESCO WORLD HERITAGE BERAT AND COBO WINERY", 
            description: "A FULL DAY TOUR",
            details: "Embark on a journey to Berat, the 'City of a Thousand Windows,' known for its stunning Ottoman architecture and historic charm. Begin at 9:00 am, exploring the UNESCO World Heritage Site of Berat with its traditional districts—Mangalemi, Gorica, and Kala. Visit Berat Castle and enjoy breathtaking views from Kala, before touring the Museum of Iconography 'Onufri,' dedicated to the renowned fresco painter. Admire the Royal Mosque, the Red Mosque, and the Holy Trinity Church. Stroll along the picturesque Gorica Arch Bridge, dating back to 1780, connecting Mangalemi and Gorica. After a traditional lunch, head to Cobo Winery for an exquisite wine tasting, featuring wines made from Albanian grapes. Return to your hotel after a rich and cultural day.",
            images: ["/images/36e5e9_47c9d62726ce41cd81687e8fdf6d5dd5~mv2.jpg"]
        },
        { 
            id: 6, 
            title: "POGRADEC, SVETI NAUM MONASTERY AND OHRID", 
            description: "A FULL DAY TOUR",
            details: "Embark on a full-day excursion along Lake Ohrid, visiting the towns of Pogradec and Tushemisht near the Macedonian border. The tour continues to the Saint Naum Monastery, a significant Orthodox shrine. Enjoy a walking tour of the UNESCO-listed old town of Ohrid, known as the Jerusalem of the Balkans, with its 365 Orthodox churches. The journey starts at 8:00 a.m. in Pogradec, a charming town on the lake’s southeastern shore, near the Macedonian border. After crossing into Macedonia, visit the Sveti Naum Monastery, founded in 895, and then enjoy lunch at a local restaurant. Continue to Ohrid, one of the oldest human settlements in Europe, famous for its architecture, ancient churches, and stunning views of the lake. Explore landmarks like Samuil Fortress, the Church of Saint Sofia, and the Kaneo Church, one of the country’s most picturesque spots. End the day with a return to your hotel.",
            images: ["/images/Ohrid-day-trip-to-Albania-Pogradec-or-Korce.jpg"]
        },
        // Shto të tjerat në mënyrë të ngjashme...
    ];

    // Funksion për të hapur modalin
    const openModal = (itinerary) => {
        setSelectedItinerary(itinerary);
    };

    // Funksion për të mbyllur modalin
    const closeModal = () => {
        setSelectedItinerary(null);
    };

    return (
        <div>
            <HeroSection />

            <div className="itineraries-container">
                <h1 className="section-title">Our Travel Itineraries</h1>
                <div className="itinerary-grid">
                    {itineraries.map((itinerary) => (
                        <div key={itinerary.id} className="itinerary-card">
                            <h5 className="itinerary-title">{itinerary.title}</h5>
                            <p className="itinerary-description">{itinerary.description}</p>
                            <button className="learn-more-btn" onClick={() => openModal(itinerary)}>
                                Learn More
                            </button>
                        </div>
                    ))}
                </div>
            </div>

            {selectedItinerary && (
                <Modal itinerary={selectedItinerary} closeModal={closeModal} />
            )}

            <Footer />
        </div>
    );
};

export default Travel;
