import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CardItem from './CardItem';
import './Cards.css';
import Modal from './pages/Modal'; // Rruga e saktë për Modal.js që ndodhet në src/components/pages

function Cards() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItinerary, setSelectedItinerary] = useState(null);

  // Funksioni për hapjen e modalit
  const openModal = (itinerary) => {
    setSelectedItinerary(itinerary);
    setIsModalOpen(true);
  };

  // Funksioni për mbylljen e modalit
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItinerary(null);
  };

  // Të dhënat e itinerareve
  const itineraryData = [
    {
      title: "DURRES AND SKANDERBEG COGNAC FACTORY DAY TOUR",
      details: "Një tur i mrekullueshëm për të mësuar më shumë rreth kulturës dhe historisë së Durrësit.",
      images: [`${process.env.PUBLIC_URL}/images/s.jpg`, `${process.env.PUBLIC_URL}/images/tirana-main-image-scaled.jpg`]
    },
    {
      title: "TIRANA AND KRUJA ON A FULL DAY TOUR",
      details: "Një udhëtim në kryeqytetin e Shqipërisë dhe qytetin historik të Krujës.",
      images: [`${process.env.PUBLIC_URL}/images/tirana-main-image-scaled.jpg`]
    },
    {
      title: "SHKODRA DAY TRIPS",
      details: "Një udhëtim i shkëlqyer për të eksploruar bukuritë natyrore të Shkodrës.",
      images: [`${process.env.PUBLIC_URL}/images/d6.jpg`]
    },
    {
      title: "POGRADEC, SVETI NAUM MONASTERY AND OHRID (FULL DAY TOUR)",
      details: "Eksploroni qytetin e Pogradec dhe vizitoni manastirin Sveti Naum.",
      images: [`${process.env.PUBLIC_URL}/images/Ohrid-day-trip-to-Albania-Pogradec-or-Korce.jpg`]
    },
    {
      title: "VLORA, KARABURUN-SAZAN NATIONAL MARINE PARK(FULL DAY TOUR)",
      details: "Një tur në natyrën e pabesueshme të Karaburunit dhe Sazanit.",
      images: [`${process.env.PUBLIC_URL}/images/146.jpg`]
    },
    {
      title: "UNESCO WORLD HERITAGE BERAT AND COBO WINERY(FULL DAY TOUR)",
      details: "Një udhëtim në Berat, qytetin e UNESCO-s dhe vizitoni vreshtat e Cobo Winery.",
      images: [`${process.env.PUBLIC_URL}/images/36e5e9_47c9d62726ce41cd81687e8fdf6d5dd5~mv2.jpg`]
    },
  ];

  return (
    <div className='cards'>
      <h1 id='cards-div'>We recommend these amazing trips!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            {itineraryData.map((itinerary, index) => (
              <CardItem 
                key={index}
                src={itinerary.images[0]} // Vendosim imazhin e parë nga itinerari
                text={itinerary.title}
                label="Albania"
                path="#"
                onClick={() => openModal(itinerary)} // Hap modalin kur klikohet
              />
            ))}
          </ul>
        </div>
      </div>

      {/* Modal që hapet kur klikohet */}
      {isModalOpen && selectedItinerary && (
        <Modal itinerary={selectedItinerary} closeModal={closeModal} />
      )}
    </div>
  );
}

export default Cards;
