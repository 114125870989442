import React, { useState } from 'react';
import Footer from '../Footer';
import HeroSection from '../HeroSection';
import './Hotels.css'; // Mund të krijosh një stil për këtë faqe
import ModalHotel from './ModalHotel'; // Importoni komponentin e modalit

const Hotels = () => {
    const [selectedHotel, setSelectedHotel] = useState(null); // Gjendja e modalit

    // Array me hotelet
    const hotels = [
        { 
            id: 1, 
            name: "Logu Harushave", 
            country: "Albania > Shkoder", 
            description: "Logu i Harushave is offering accommodations in Theth.",
            details: "The hotel is located in the heart of Albanian Alps , THETH . One of the best accommodation you can find in Theth with bio food and warmest hospitality .",
            images: ["Logu.jpg", "Logu1.jpg", "Logu2.jpg"]  
        },
        { 
            id: 2, 
            name: "Grand Europa", 
            country: "Albania > Shkoder", 
            description: "This 5-star hotel is located in the centre of Shkodra ",
            details: "This 5-star hotel is located in the center of Shkodra and features a spa and fitness center, which are accessible for free.",
            images: ["Grand.jpg", "Grand1.jpg", "Grand2.jpg"]
        },
        { 
            id: 3, 
            name: "Tradita", 
            country: "Albania > Shkoder", 
            description: "Located in Shkodër, one of the oldest and most historic places in Albania",
            details: "Tradita Hotel is a traditional boutique hotel housed in an Albanian traditional house that is around 1 km from the center of town.",
            images: ["Tradita.jpg", "Tradita1.jpg", "Tradita2.jpg"]
        },
        { 
            id: 4, 
            name: "Golden Palace", 
            country: "Albania > Shkoder", 
            description: "Ideally located on Bulevardi Skënderbeu in Shkodër",
            details: "Located 30 miles from Port of Bar, Hotel Golden Palace offers 5-star accommodations in Shkodër and features a shared lounge, a restaurant and a bar.",
            images: ["Golden.jpg", "Golden2.jpg", "Golden1.jpg"]
        },
        { 
            id: 5, 
            name: "Breezy", 
            country: "Albania > Valbone", 
            description: "Set in Valbonë, Breezy Hotel ",
            details: "Set in Valbonë, Breezy Hotel has a garden, bar and free WiFi. There is a restaurant serving Italian cuisine, and free private parking is available.",
            images: ["Breezy.jpg", "Breezy1.jpg", "Breezy2.jpg"]
        },
        { 
            id: 6, 
            name: "Bologna", 
            country: "Albania > Vlore", 
            description: "Bologna hotel, Vlorë, Albania.",
            details: "Well-known for its family-friendly environment and proximity to great restaurants and attractions, Hotel Bologna makes it easy to enjoy the best of Vlore. ",
            images: ["Bologna.jpg", "Bologna2.jpg", "Bologna1.jpg"]
        },
        { 
            id: 7, 
            name: "Nobus", 
            country: "Albania > Vlora", 
            description: "Set in Vlorë, 300 metres from Sunny Beach, Nobus Hotel ",
            details: "Set in Vlorë, 300 metres from Sunny Beach, Nobus Hotel & Spa offers accommodation with an outdoor swimming pool, free private parking, a garden and a terrace.",
            images: ["Nobus.jpg", "Nobus1.jpg", "Nobus2.jpg"]
        },
       
        { 
            id: 8, 
            name: "Royal G Max", 
            country: "Albania > Durres", 
            description: "Royal G Max Hotel & Spa",
            details: "Royal G Max Hotel & Spa is located in the magnificent seashore of the Adriatic Sea, only 20 minutes away from Durrës city center.",
            images: ["Royal.jpg", "Royal1.jpg",  "Royal2.jpg"]
        },
        { 
            id: 9, 
            name: "Marika", 
            country: "Albania > Durres", 
            description: "Situated in Golem, 400 metres from Golem Beach",
            details: "Situated in Golem, 400 metres from Golem Beach, Hotel Marika features accommodation with a garden, free private parking, a private beach area and a terrace.",
            images: ["Marika.jpg", "Marika2.jpg", "Marika1.jpg"]
        },
        { 
            id: 10, 
            name: "AS HOTEL", 
            country: "Albania > Durres", 
            description: "Located in the town of Golem, 3.1 mi from Durrës.",
            details: "This design hotel has an excellent location on the Golem beach and also 20 minutes from the airport. There are spectacular views from pool and the rooftop",
            images: ["As.jpg", "As1.jpg", "As2.jpg"]
        },



    ];

    // Funksion për të hapur modalin
    const openModal = (hotel) => {
        setSelectedHotel(hotel);
    };

    // Funksion për të mbyllur modalin
    const closeModal = () => {
        setSelectedHotel(null);
    };

    return (
        <div>
            <HeroSection />
   

            <div className="hotels-container">
                <h1 className="section-title">Our Featured Hotels</h1>
                <div className="hotel-grid">
                    {hotels.map((hotel) => (
                        <div key={hotel.id} className="hotel-card">
                            <h5 className="hotel-title">{hotel.name}</h5>
                            <p className="hotel-country">{hotel.country}</p>
                            <p className="hotel-description">{hotel.description}</p>
                            <button className="learn-more-btn" onClick={() => openModal(hotel)}>
                                Learn More
                            </button>
                        </div>
                    ))}
                </div>
            </div>

            {selectedHotel && (
                <ModalHotel hotel={selectedHotel} closeModal={closeModal} />
            )}

            <Footer />
        </div>
    );
};

export default Hotels;
